import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Itison Customer Story - Rotaready"
          description="Since implementing Rotaready across all seven of their venues, Itison has achieved an ROI of over 760%."
          url="customer-stories/hospitality-itison"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Outdoor gardens with light show"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Hospitality" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    style={{ height: '50px' }}
                    alt="Itison logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Since implementing Rotaready across all seven of their venues, Itison has achieved an ROI of over 760%." />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="7" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="169" />
              <StatSubtitle text="employees" />
            </StatItem>

            <StatItem>
              <StatHeadline text="764%" />
              <StatSubtitle text="ROI" />
            </StatItem>

            <StatItem>
              <StatHeadline text="25.5 hours" />
              <StatSubtitle text="better deployed every week!" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="Itison" />
              </Content>

              <Content>
                <TextBlock text="Itison is a group of beautiful and eclectic venues based in Glasgow. Starting out with its first site in 2007 the hospitality business now consists of 7 venues across the city." />
              </Content>

              <Content>
                <TextBlock text="Until recently, Itison was using a combination of spreadsheets and a software solution to build rotas and control costs. But the use of spreadsheets was time consuming and left a lot of room for error. Furthermore their software lacked flexibility and the HR functionalities the business required." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="A single solution for multiple challenges" />
              </Content>

              <Content>
                <TextBlock text="So, in 2021 the Itison team decided to set about their search for a solution that could help them with the challenges they were facing. The initial requirements were based around HR, focussing on the likes of secure, cloud based storage for employee documentation and a way of easily issuing contracts." />
              </Content>

              <Content>
                <TextBlock text="But as the search progressed, the team realised there were solutions out there that could help them with far more than their initial set of challenges; such as inefficient rota building and attendance tracking. So the search transformed into one for an end to end workforce management solution." />
              </Content>

              <Content>
                <TextBlock text="In addition to functional requirements, cost was also a key factor for the business..." />
              </Content>
            </Section>

            <Section>
              <Content>
                <Blockquote
                  text="Like with all things you buy, workforce management software comes with a cost. You have to make sure the cost is relative to the hours or hassle that it saves you. Rotaready delivers on both fronts."
                  attribution="Neil Lockie, Financial Controller"
                />
              </Content>

              <Content>
                <TextBlock text="After reviewing a number of solutions, Itison decided Rotaready was the best solution for the business." />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline1.childImageSharp.gatsbyImageData}
                alt="Wedding venue with candles and fairy lights"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Effortless implementation" />
              </Content>

              <Content>
                <TextBlock text="From making the decision to go with Rotaready, it took roughly three weeks to get all sites up and running. Efficiency being another of Itison's requirements for its chosen workforce management partner." />
              </Content>

              <Content>
                <Blockquote
                  text="We had a number of criteria for our chosen solution, but ultimately we were looking for flexibility, price and the speed of getting it up and running. Rotaready ticked all three boxes."
                  attribution="Neil Lockie, Financial Controller"
                />
              </Content>

              <Content>
                <Blockquote
                  text="Implementing Rotaready was very very easy. We got a lot of support from Rotaready themselves which was great. And to be honest it's a very user friendly system so it's easy to pick up on a lot of things. Our team finds it very straightforward to use."
                  attribution="Neil Lockie, Financial Controller"
                />
              </Content>

              <Content>
                <TextBlock text="Itison has since opened two new pop-up venues, both of which were effortlessly set up with unique rota configurations." />
              </Content>

              <Content>
                <H3 uistyle="brand160" text="Top 3 features" />
              </Content>

              <Content>
                <TextBlock text="Since implementing Rotaready, the Itison team has been using the majority of its features, but everyone has their favourites. Here's the team's top three..." />
              </Content>

              <Content>
                <H4 uistyle="primary" text="1. Rota preparation and associated reporting" />
                <H4 uistyle="primary" text="2. The ability to forecast and sales projections" />
                <H4 uistyle="primary" text="3. Issuing and storing contracts" />
              </Content>

              <Content>
                <Blockquote
                  text="We have so many staff that issuing and storing contracts has always been a big task. Thankfully Rotaready now makes this a painless task."
                  attribution="Neil Lockie, Financial Controller"
                />
              </Content>

              <Content>
                <H3 uistyle="brand160" text="Benefits across the board" />
              </Content>

              <Content>
                <TextBlock text="As for the benefits, the Itison team has seen since getting started with Rotaready..." />
              </Content>

              <Content>
                <TextBlock text="Building and sharing staff rotas is now effortless for managers." />
              </Content>

              <Content>
                <TextBlock text="The HR team can issue employee contracts directly from Rotaready, with secure storage and easy access of employee records." />
              </Content>

              <Content>
                <TextBlock text="As for the finance team, they have much greater visibility over costs thanks to Rotaready's Cost Control. This has enabled them to easily and accurately compare forecasts against actual commercial performance across the business." />
              </Content>

              <Content>
                <Blockquote
                  text="In hospitality it's one thing building the rota, it's another thing making sure it's cost effective. To have that live visibility is great."
                  attribution="Neil Lockie, Financial Controller"
                />
              </Content>

              <Content>
                <TextBlock>
                  Payroll is also more accurate than ever thanks to Rotaready's payroll export, which allows automatically rounded <Link style="color:inherit;" to="/time-attendance">time and attendance</Link> data to be seamlessly exported from from Rotaready, into Itison's <Link style="color:inherit;" to="/payroll">payroll</Link> solution.
                </TextBlock>
              </Content>

              <Content>
                <TextBlock text="Last but not least, one of the main benefits of Rotaready for all departments has been the live support available to them..." />
              </Content>

              <Content>
                <Blockquote
                  text="Rotaready's support is very good, our managers absolutely rave about the live chat! You get an answer very quickly, they're very thorough and efficient. It's nice to get the answer there and then rather than wait on a chain of emails. My team is delighted with it."
                  attribution="Neil Lockie, Financial Controller"
                />
              </Content>
            </Section>

            <Section>
              <PopQuote text="A combination of all of these things has helped Itison save over 25 hours every week and achieve an ROI of 764%!" />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Next on the agenda" />
              </Content>

              <Content>
                <TextBlock text="Going forward, Rotaready's customer success team will continue to work closely with Itison to ensure they're leveraging all the benefits of the platform, while feeding back to the engineering team to ensure the product remains as customer-centric as possible." />
              </Content>
            </Section>

            <Section>
              <Content>
                <Blockquote
                  text="There's nothing Rotaready can't do that we want it to do. And the few things we asked for in the past were already being developed. I'd definitely recommend Rotaready to other hospitality businesses."
                  attribution="Neil Lockie, Financial Controller"
                />
              </Content>
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_itison"
                label="rotaready.com/customer-stories/hospitality-itison"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-Itison.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/itison/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/itison/logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 60, quality: 100)
      }
    }

    inline1: file(relativePath: { eq: "customer-stories/itison/inline1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
